import React from "react";
import parsePhoneNumber from 'libphonenumber-js'
import styles from "./styles/contactsColumn.module.scss";


const ContactsColumn = ({data}) => {
    const {
        mobilePhone, homePhone, additionalContacts = {},
        kitContacts = {},
        additionalLocalEmergencyContacts = {},
        localEmergencyContacts = {},
        kitContactList = [],
        additionalContactList = [],
        localEmergencyContactList = [],
        additionalLocalEmergencyContactList = []
    } = data.patientInfo;

    const formatPhoneNumbers = (data)=> Object.keys(data).map(name=> ({
        type: name,
        phoneNumber: data[name]
    }))

    const additionalContactsFormatted = additionalContactList.length ? additionalContactList : formatPhoneNumbers(additionalContacts)
    const additionalLocalEmergencyContactsFormatted = additionalLocalEmergencyContactList.length ?
        additionalLocalEmergencyContactList :
        formatPhoneNumbers(additionalLocalEmergencyContacts)
    const localEmergencyContactsFormatted = localEmergencyContactList.length ?
        localEmergencyContactList :
        formatPhoneNumbers(localEmergencyContacts)
    const kitContactsFormatted = kitContactList.length ?
        kitContactList.map(({phoneNumber}) => phoneNumber) :
        Object.values(kitContacts)

    let contactNumbers = [];
    const emergencyContactNumbers = [...additionalLocalEmergencyContactsFormatted, ...localEmergencyContactsFormatted];

    if (mobilePhone) {
        contactNumbers = [{type: 'Mobile Phone', phoneNumber: mobilePhone}, ...contactNumbers]
    }
    if (homePhone) {
        contactNumbers = [{type: 'Home Phone', phoneNumber: homePhone}, ...contactNumbers]
    }

    if (!!Object.keys(kitContactsFormatted).length) {
        contactNumbers = [...contactNumbers, {type: 'Kit Phone(s)', phoneNumber: kitContactsFormatted}]

    }
    let isContactNumbersEmpty = Object.keys(contactNumbers).length === 0;
    let isAdditionalContactsEmpty = Object.keys(additionalContactsFormatted).length === 0;
    let isEmergencyContactNumbersEmpty = Object.keys(emergencyContactNumbers).length === 0;


    const renderColumn = (title, data) => {
        const dataKeys = data.map(({type}) => type);
        const dataValues = data.map(({phoneNumber}) => phoneNumber).reduce((acc, el) => acc.concat(el), []).map(el => {
            const phoneNumberParsed = parsePhoneNumber(el);
            return phoneNumberParsed ? phoneNumberParsed.formatNational() : el;
        });
        return <div className={styles.contactInfoDataColumn}>
            <div className={styles.contactColumnTitle}>{title}</div>
            <div className={styles.contactInfoDataColumnBody}>
                {!!dataKeys.length && (
                    <div className={styles.contactFieldLabelColumn}>
                        {dataKeys.map((key, index) =>
                            (<div key={index} className={styles.contactFieldDivider}>{key}</div>))}
                    </div>
                )}
                {!!dataValues.length && <div className={styles.contactFieldValueColumn}>
                    {dataValues.map((value, index) =>
                        (<div key={index} className={styles.contactFieldDivider}>{value}</div>))}
                </div>}
            </div>
        </div>
    };



    return (<div className={styles.contactsInfo}>
        <div className={styles.contactInfoFlexContainer}>
            <div className={styles.contactInfoDataContainer}>
                {!isContactNumbersEmpty && renderColumn('Contact Numbers', contactNumbers)}
                {!isAdditionalContactsEmpty && renderColumn('Additional Contacts', additionalContactsFormatted)}
                {!isEmergencyContactNumbersEmpty && renderColumn('Emergency Numbers', emergencyContactNumbers)}
            </div>
        </div>
    </div>)
};

export default ContactsColumn