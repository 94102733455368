import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import MhLogo from '../../MH-logo.png';
import {ReactComponent as KeyIcon} from '../../icons/vpn_key_black_24dp.svg';
import styles from './styles.module.scss';

const Login = () => {
    const history = useHistory();
    const queryParams = new URLSearchParams(useLocation().search);

    const error = queryParams.get('error');
    const [accessCode, setAccessCode] = useState('');
    return (
        <div className={styles.loginPageContainer}>
            <form onSubmit={(event) => {
                event.preventDefault();
                sessionStorage.setItem('token', accessCode);
                history.push('/');
            }}>
                <Paper className={styles.loginBlockContainer}>
                    <img className={styles.mhImage} src={MhLogo} alt=""/>
                    <div className={styles.loginTitle}>Rollover Call Dashboard</div>

                    {error && <div className={styles.error}>{error}</div>}

                    <div className={styles.accessCodeContainer}>

                        <Paper classes={{root: styles.loginTextField}}>
                            <div className={styles.loginTextFieldIconContainer}>
                                <KeyIcon/>
                            </div>
                            <InputBase
                                value={accessCode}
                                classes={{root: styles.loginTextFieldInput}}
                                placeholder="Access Code"
                                inputProps={{'aria-label': 'Access Code'}}
                                onChange={(e) => setAccessCode(e.target.value)}
                            />
                        </Paper>
                    </div>

                    <Button type='submit' disabled={!accessCode} classes={{root: styles.loginButton}}>
                        Log In
                    </Button>
                </Paper>

            </form>
            <div className={styles.copyright}>(C) 2021 Medically Home Group, Inc.</div>
        </div>

    );
};

export default Login