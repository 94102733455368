import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import Paper from '@material-ui/core/Paper';
import SocketService from '../../services/socket';
import PatientInfoColumn from './PatientInfoColumn';
import ContactsColumn from './ContactsColumn';
import ItemHeader from './ItemHeader';
import MHLoader from './Loader';
import AppHeader from './Header';
import styles from './styles/styles.module.scss';

const CallsList = () => {
    const history = useHistory();
    const socket = new SocketService();
    const {calls, loading} = useSelector(({callsReducer}) => ({
        calls: callsReducer.calls,
        loading: callsReducer.loading
    }), shallowEqual);

    const dispatch = useDispatch();

    const setConnected = () => {
        dispatch({type: 'CONNECTION_INIT'})
    };
    const setDisconnected = () => {
        dispatch({type: 'CONNECTION_LOST'})
    };

    const checkIfBrowserIsInViewAndReconnect = (socket) => {
        if (document.visibilityState !== "hidden") {
            socket.init(history, {
                onMessage: (data) => dispatch({type: 'GET_CALLS', payload: data}),
                onInit: setConnected,
                onConnectionLost: setDisconnected
            });
        }
    };


    useEffect(() => {
        window.addEventListener('offline', setDisconnected);
        window.addEventListener('online', setConnected);
        return () => {
            window.removeEventListener('offline', setDisconnected);
            window.removeEventListener('online', setConnected);
        };
    }, [dispatch]);


    useEffect(() => {
        // Here we init the socket
        socket.init(history, {
            onMessage: (data) => dispatch({type: 'GET_CALLS', payload: data}),
            onInit: setConnected,
            onConnectionLost: setDisconnected
        });

        const interval = setInterval(() => {
            dispatch({type: 'UPDATE_DATE'})
        }, 1000);

        return () => {
            socket.disconnect('component unmounted');
            clearInterval(interval);
            dispatch({type: 'CLEAR_REDUCER'})
        }
    }, [dispatch, history]);


    return (
        <div className={styles.callsListContainer}>
            <AppHeader/>
            {loading && <div className={styles.loadingContainer}><MHLoader/></div>}
            {!loading && <>
                {calls.length === 0 && <div className={styles.noData}>There are no recent rollover calls.</div>}
                {calls.map((el, index) => {
                    const {callType} = el;
                    return (
                        <Paper key={index} className={styles.callItemContainer}>
                            <ItemHeader data={el}/>
                            {callType !== 'Unrecognized Caller' &&
                            <div className={styles.contentBlock}>
                                <PatientInfoColumn data={el}/>
                                <ContactsColumn data={el}/>
                            </div>}
                        </Paper>
                    )
                })}
            </>}
        </div>
    );
};


export default CallsList