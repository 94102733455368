const getTimeDifferenceFromMiliSeconds = (duration) => {
    let seconds = parseInt((duration / 1000) % 60)
        , minutes = parseInt((duration / (1000 * 60)) % 60)
        , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + "h : " + minutes + "m : " + seconds+'s';
};

const getDifferenceInYears = (toCompare, compareWith) => {
    let ynew = compareWith.getFullYear();
    let mnew = compareWith.getMonth();
    let dnew = compareWith.getDate();
    let yold = toCompare.getFullYear();
    let mold = toCompare.getMonth();
    let dold = toCompare.getDate();
    let diff = ynew - yold;
    if (mold > mnew) diff--;
    else {
        if (mold === mnew) {
            if (dold > dnew) diff--;
        }
    }
    return diff;

};

const formatDate = (date) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1; //Months are zero based
    let year = d.getFullYear();
    return `${month}/${day}/${year}`;
};


export {getTimeDifferenceFromMiliSeconds, getDifferenceInYears, formatDate}