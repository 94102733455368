import React from 'react';
import PhoneCallTimeAndDiff from './PhoneCallTimeAndDiff';
import {ReactComponent as AccountCircleIcon} from "../../../icons/account_circle_black.svg";
import {ReactComponent as ErrorIcon} from "../../../icons/error_outline_black.svg";
import {ReactComponent as HelpIcon} from "../../../icons/help_outline_black.svg";

import styles from '../styles/itemHeader/styles.module.scss';

const iconMap = {
    "Unrecognized Caller": <HelpIcon className={styles.callIcon}/>,
    "Emergency Call": <ErrorIcon className={styles.callIcon}/>,
    "Ordinary": <AccountCircleIcon className={styles.callIcon}/>
};

const ItemHeader = ({data}) => {
    const {lastName, firstName} = data.patientInfo;
    const {initiationTimestamp, callType, originatingPhone} = data;

    const patientName = !firstName && !lastName ? '- ' : `${lastName}${lastName && ','} ${firstName}`;

    const isUnrecognized = callType === 'Unrecognized Caller';
    const isEmergency = callType === 'Emergency Call';

    return (
        <div className={
            `${styles.callItemHeader} 
                     ${isEmergency ? styles.emergencyCallBackground : ''} 
                     ${isUnrecognized ? styles.unrecognizedCallBackground : ''}`
        }>
            <div
                className={styles.callItemHeaderLeft}>
                <span
                    className={`${styles.callItemHeaderPatientName} 
                    ${isEmergency ? styles.callTypeEmergencyText : ''} 
                    ${isUnrecognized ? styles.callTypeUnrecognizedText : ''}
                `}>
                    {iconMap[callType]}
                    {isUnrecognized ? 'Unrecognized Caller' : patientName}</span>
                <PhoneCallTimeAndDiff originatingPhone={originatingPhone} initiationTimestamp={initiationTimestamp}/>
            </div>
            {isEmergency &&
            <div className={`${styles.callTypeEmergencyLabel}`}>Emergency</div>}
        </div>


    )
};

export default ItemHeader;