import React from "react";
import {getDifferenceInYears, formatDate} from "../../utils";
import {ReactComponent as PatientIcon} from "../../icons/account_circle_black_24dp.svg";
import {ReactComponent as CalendarIcon} from "../../icons/event_black_24dp.svg";
import {ReactComponent as AssignmentIcon} from "../../icons/assignment_black_24dp.svg";
import {ReactComponent as GenderIcon} from "../../icons/gender.svg";
import {ReactComponent as NurseRequired} from "../../icons/nurse_required.svg";
import {ReactComponent as LocationIcon} from "../../icons/place_black_24dp.svg";
import {ReactComponent as ProgramIcon} from "../../icons/corporate_fare_black.svg";

import styles from "./styles/patientInfoColumn.module.scss";

const PatientInfoColumn = ({data}) => {
    const {lastName, firstName, mrn, gender, birthDate, address = {}, program, assignedNurse = {}} = data.patientInfo;
    const {address1, address2, city, state, zip} = address;

    const patientName = !firstName && !lastName ? '- ' : `${lastName}${lastName && ','} ${firstName}`;

    const nurseFirstName = assignedNurse?.firstName;
    const nurseLastName = assignedNurse?.lastName;

    const assignedNurseName = !nurseFirstName && !nurseLastName ?
        '- ' : `${nurseLastName}${nurseLastName && ','} ${nurseFirstName}`;


    const birthDateFormatted = birthDate ?
        `${formatDate(birthDate)}, ${getDifferenceInYears(new Date(birthDate), new Date())}`
        : '-';


    return (<div className={styles.patientInfo}>
            <div className={styles.patientInfoTitle}>Patient Info</div>
            <div className={styles.patientInfoDataContainer}>
                <div className={styles.patientInfoDataColumn}>
                    <div className={styles.patientInfoDataRow}>
                        <div className={styles.fieldLabel}><PatientIcon/>Patient</div>
                        <div className={styles.fieldValue}>{patientName}</div>
                    </div>
                    <div className={styles.patientInfoDataRow}>
                        <div className={styles.fieldLabel}><CalendarIcon/>DOB</div>
                        <div
                            className={styles.fieldValue}>{birthDateFormatted} y.o
                        </div>
                    </div>
                    <div className={styles.patientInfoDataRow}>
                        <div className={styles.fieldLabel}><GenderIcon/>Gender</div>
                        <div className={styles.fieldValue}>{gender || '-'}</div>
                    </div>
                    <div className={styles.patientInfoDataRow}>
                        <div className={styles.fieldLabel}><AssignmentIcon/>MRN</div>
                        <div className={styles.fieldValue}>{mrn || '-'}</div>
                    </div>
                    <div className={styles.patientInfoDataRow}>
                        <div className={`${styles.fieldLabel} ${styles.assignedNurse}`}><NurseRequired/><div>Assigned Nurse</div></div>
                        <div className={styles.fieldValue}>{assignedNurseName || '-'}</div>
                    </div>
                </div>

                <div className={styles.patientInfoDataColumn}>
                    <div className={styles.patientInfoDataRow}>
                        <div className={styles.fieldLabel}><ProgramIcon/>Program</div>
                        <div className={styles.fieldValue}>{program || '-'}</div>
                    </div>
                    <div className={styles.patientInfoDataRow}>
                        <div className={`${styles.fieldLabel} ${styles.addressField}`}><LocationIcon/>Address</div>
                        <div className={styles.fieldValue}>
                            {address1 && <div>{address1}</div>}
                            {address2 && <div>{address2}</div>}
                            <div>{city && `${city},`} {state && state} {zip && zip}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientInfoColumn