const logEventInTime = (message, eventToLog) => {
    console.log(message, new Date().toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    }), 'event ', eventToLog);

};
export default class SocketService {
    constructor() {
        if (this.constructor.instance) {
            return this.constructor.instance;
        }
        this.socket = null;
        this.additionalReconnectCount = 0;
        this.constructor.instance = this;
    }

    init = (history, {onMessage, onInit, onConnectionLost}, isAdditionalReconnect) => {
        const token = sessionStorage.getItem('token');
        this.socket = new WebSocket(`${window.RUNTIME_CONFIG.REACT_APP_API_URL}?token=${token}`);

        this.socket.addEventListener('open', (event) => {
            logEventInTime('Connection status: connected', event);
            onInit && onInit();
            if (isAdditionalReconnect) this.additionalReconnectCount = 0;
            this.socket.send(JSON.stringify({action: 'init'}));
        });

        this.socket.addEventListener('message', (event) => {
            onMessage && onMessage(JSON.parse(event.data));
        });

        this.socket.addEventListener('close', (event) => {
            const isLogout = event.reason === 'LOGOUT';
            logEventInTime('Connection status: closed', event);
            onConnectionLost && onConnectionLost();
            // always reconnect on 'Going Away' reason
            if (event.reason === 'Going away') {
                logEventInTime('Connection status: closed/reconnecting', event);
                setTimeout(() => {
                    this.init(history, {onMessage, onInit, onConnectionLost});
                }, 4000);
                // if disconnect reason is not 'Going Away' try to reconnect once, if fails
            } else if (this.additionalReconnectCount < 1 && !isLogout) {
                logEventInTime('Connection status: closed: additional reconnect in progress', event);
                this.additionalReconnectCount += 1;
                this.init(history, {onMessage, onInit, onConnectionLost}, true);
            } else if (this.additionalReconnectCount >= 1 || isLogout) {
                // if number of error reconnects is more than 1(this means that token is invalid)
                // or user pressed logout, we clear everything and go to login
                let logMessage = 'Connection status: closed/invalid token';
                if (isLogout) logMessage = 'Connection status: closed/user logged out manually';
                logEventInTime(logMessage, event);
                let url = '/login?error=Wrong credentials';
                if (isLogout) url = '/login';
                history.push(url);
                sessionStorage.clear();
                this.constructor.instance = null;
            }

        });
    };
    disconnect = (reason) => {
        this.socket.close(1000, reason);
    };

}




