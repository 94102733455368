const initialState = {
    calls: [],
    currentDateTime: new Date(),
    loading: true,
    connected: false,
};

const CallsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CALLS': {
            return {
                ...state,
                calls: [...action.payload],
                loading: false
            }
        }
        case 'UPDATE_DATE': {
            return {...state, currentDateTime: new Date()}
        }
        case 'CLEAR_REDUCER': {
            return initialState
        }
        case 'CONNECTION_INIT': {
            return {...state, connected: true}
        }
        case 'CONNECTION_LOST': {
            return {...state, connected: false}
        }

        default:
            return {...state};
    }
};

export default CallsReducer;
