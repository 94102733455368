import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        // margin: '0 10px',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
    }
}));

export default function MHLoader({className, rootClassName, ...other}) {
    const classes = useStyles();
    return (
        <div className={`${classes.container} ${rootClassName}`}>
            <CircularProgress classes={{root: `${classes.root} ${className || ''}`}} {...other} />
        </div>
    );
}
