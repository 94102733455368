import React from 'react';
import {useSelector} from "react-redux";
import parsePhoneNumber from 'libphonenumber-js'
import Button from '@material-ui/core/Button';
import SocketService from '../../services/socket';
import logo from "../../MH-logo.png";
import styles from './styles/header.module.scss';

const Header = () => {
    const socket = new SocketService();
    const connected = useSelector(({callsReducer}) => callsReducer.connected);
    const commandCenterPhoneNumber = parsePhoneNumber(window.RUNTIME_CONFIG.COMMAND_CENTER_PHONE);
    let commandCenterPhoneNumberFormatted = window.RUNTIME_CONFIG.COMMAND_CENTER_PHONE;

    if (commandCenterPhoneNumber) {
        commandCenterPhoneNumberFormatted = commandCenterPhoneNumber.formatNational();
    }


    return (
        <>
            <div className={styles.headerBlock}>
                <img alt='logo' className={styles.logo} src={logo}/>
                <Button onClick={() => {
                    socket.disconnect('LOGOUT');
                }}>Logout</Button>
            </div>
            <div className={styles.commandCenter}>
                <div className={styles.commandCenterLeft}>
                    <div className={styles.commandCenterName}>{window.RUNTIME_CONFIG.COMMAND_CENTER_NAME}</div>
                    {connected && <div className={styles.labelConnected}>Connected</div>}
                    {!connected && <div className={styles.labelDisconnected}>Disconnected</div>}
                </div>
                <div className={styles.commandCenterPhone}>{commandCenterPhoneNumberFormatted}</div>
            </div>
        </>
    );
};


export default Header