import {useHistory} from 'react-router-dom';


const AuthWrapper = ({componentToRender}) => {
    const token = sessionStorage.getItem('token');
    const history = useHistory();
    if (token) return componentToRender;
    history.push('/login');

    return null;
};

export default AuthWrapper