import React from 'react';
import {Provider} from 'react-redux'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import CallsList from './components/CallsList';
import Login from './components/Login';
import AuthWrapper from './components/AuthWrapper';
import store from './store';

function App() {
    return (
        <Provider store={store}>
            <div className="App">
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <AuthWrapper componentToRender={<CallsList/>}/></Route>
                        <Route path="/login">
                            <Login/>
                        </Route>
                        <Redirect
                            to={'/login'}
                        />
                    </Switch>
                </Router>
            </div>
        </Provider>
    );
}

export default App;
