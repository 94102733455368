import React from "react";
import parsePhoneNumber from 'libphonenumber-js'
import {useSelector} from "react-redux";
import {getTimeDifferenceFromMiliSeconds} from "../../../utils";
import {ReactComponent as PhoneIcon} from "../../../icons/call_black.svg";
import {ReactComponent as TimeIcon} from "../../../icons/schedule_black.svg";
import styles from "../styles/itemHeader/callTimeAndDiff.module.scss";

const PhoneCallTimeAndDiff = ({initiationTimestamp, originatingPhone}) => {
    const currentDateTime = useSelector((state) => state.callsReducer.currentDateTime);
    const callTime = new Date(initiationTimestamp);
    const callTimeFormatted = callTime.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
    const difference = currentDateTime.getTime() - callTime.getTime();
    const phoneParsed = parsePhoneNumber(originatingPhone);

    return (
        <div className={styles.phoneAndTimeContainer}>
            <span className={styles.originatingPhone}>
                <PhoneIcon
                    className={styles.phoneSvgEl}/>{phoneParsed ? phoneParsed.formatNational() : originatingPhone}
            </span>
            <span className={styles.callTime}>
                <TimeIcon className={styles.timeSvgEl}/>
                {callTimeFormatted}
            </span>
            <span
                className={styles.callTimeDiff}>
            {getTimeDifferenceFromMiliSeconds(difference)} ago
        </span>
        </div>

    );

};
export default PhoneCallTimeAndDiff;